.login-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #1D2833;
  color: #fff;
  overflow: hidden;
}
.bgLoginPg {
  background-image: url("/images/bgLogin.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.09;
  inset: 7px 4px 0px 0px;
  flex: 0 0 auto;
  mix-blend-mode: lighten;
  overflow: visible;
  z-index: 1;
  height: 1590px;
  left: calc(49.59202175883955% - 1736px / 2);
  position: absolute;
  top: calc(51.164958061509815% - 1590px / 2);
  width: 1736px;
}

.login-page .login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 50;
  max-width: 360px;
  padding: 0 6px;
  position: relative;
}

.login-page .login-area .login-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  -webkit-animation: Animation 15s ease infinite;
  -moz-animation: Animation 15s ease infinite;
  animation: Animation 15s ease infinite;
  border-radius: 18px;
  padding: 50px 0px;
  position: relative;
}
.login-title .logoLogin {
  width: 150px;
}
.login-page .login-area .login-form .login-title {
  color: #fff !important;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}
.login-page .login-area .login-form h1 {
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
  font-size: 24px;
}
.login-page .login-area .login-form p {
  opacity: 0.7;
  margin-bottom: 30px;
}
.login-page .login-area .login-form .login-text p{
  color: white !important;
  font-weight: 600;
}
.login-page .login-form .login-field {
  width: 100%;
  margin-bottom: 40px;
}
.MuiButton-label .xdc {
  margin-left: 10px;
}
.login-page .login-area .login-form .validator-form .login-btn {
  width: 100%;
  border: none;
  padding: 14px 20px;
  color: #000 !important;
  font-size: 16px;
  min-height: 40px;
  line-height: 30px;
  font-weight: 700;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: unset !important;
}
.validator-form{
  width: 100%;
}